import ajax from '@/utils/ajax'
import getEnv from '@/utils/env'
import { getCookie, deleteCookie, setAuthCookie } from '@/utils/cookies'

// Initial state
const state = {
  token: getCookie(getEnv('VUE_APP_AUTH_COOKIE_NAME')) || null,
  currentUser: null,
  status: null,
  error: null
}

// Getters
const getters = {
  isAuthenticated: state => !!state.token,
  currentUser: state => state.currentUser,
  authLoading: state => state.status === 'loading',
  authStatus: state => state.status,
  authError: state => state.error
}

// Mutations
const mutations = {
  AUTHENTICATE_PENDING: state => {
    state.error = null
    state.status = 'loading'
  },
  AUTHENTICATE_SUCCESS: (state, { token, user }) => {
    state.error       = null
    state.status      = 'success'
    state.token       = token
    state.currentUser = user
  },
  AUTHENTICATE_FAILURE: (state, error) => {
    state.status      = 'error'
    state.error       = error ? error : 'unknown_error'
    state.token       = null
    state.currentUser = null
  },
  LOGOUT: state => {
    state.error       = null
    state.token       = null
    state.currentUser = null
  }
}

// Actions
const actions = {
  async authenticate({ commit }, body = {}) {
    commit('AUTHENTICATE_PENDING')
    try {
      const response = await ajax('login', {
        method: 'POST',
        body
      })
      if (response.error) commit('AUTHENTICATE_FAILURE', response.error)
      else {
        setAuthCookie(response.token)
        commit('AUTHENTICATE_SUCCESS', response)
      }
      return response
    } catch (error) {
      commit('AUTHENTICATE_FAILURE', error.message ? error.message : error)
      return error.message ? error.message : error
    }
  },
  logout: ({ commit }) =>
    new Promise((resolve, reject) => {
      try {
        commit('LOGOUT')
        deleteCookie(getEnv('VUE_APP_AUTH_COOKIE_NAME'))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
}

export default {
  state,
  getters,
  actions,
  mutations
}
