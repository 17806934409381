<template>
<div align="center">
  <img class="image" :src="getImageURL(machine.nrmm_type)"/>
</div>
</template>
<script>
export default {
  name: "MachineIcon",
  props: ['machine'],
  methods: {
    getImageURL(nrmmType) {
    nrmmType = this.machine.nrmm_type.split('/').join('').split(' ').join('_').toLowerCase()
    const availableIcons = [
      'asphalt_roller',
      'bulldozer',
      'concrete_mixer_truck',
      'concrete_mixer',
      'fixed_crane',
      'forklift',
      'hydraulic_excavator',
      'mobile_crane',
      'paver__finisher',
      'piling_equipment',
      'truck'
    ]
    const image = require.context('@/assets/icons/', false, /\.png$/)
    if (availableIcons.indexOf(nrmmType) !== -1) {
      return image(`./${nrmmType}.png`)
    } else {
      return image('./exhaust_pipe.png')
    }
    }
  }
}
</script>
<style scoped>
.image {
  max-height: 50px;
  margin: 0 auto;
}
</style>