<template>
  <div class="view">
    <h1 class="title">Projecten</h1>
    <div>
      <Map class="map" :projects="projects"/>
    </div>
    <div class="tile is-vertical is-12">
      <ProjectRow class="tile is-child is-12" v-for="project in projects" :key="project.id"  :project="project"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Map from '@/components/Map'
import ProjectRow from '@/components/ProjectRow'
export default {
  name: 'Projects',
  components: {
    Map,
    ProjectRow
  },
  computed: {
    ...mapGetters(['projects'])
  },
  beforeCreate() {
    // Fetch all projects before creating this view
    this.$store.dispatch('getProjects')
  }
}
</script>
<style scoped>
.map{
  margin-bottom:10px;
}
</style>
