import { createApp } from 'vue'
// import Vue from 'vue'
// Vue.config.devtools = true
import App from '@/App'
import router from '@/router'
import store from '@/store'


// Import Luxon
import { DateTime, Settings } from 'luxon'
Settings.defaultLocale = 'nl'

// Import Font Awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
library.add(faHeart)

// Import general styling
import '@/styles/main.scss'

const app = createApp(App)
app.use(router)
app.use(store)
app.config.globalProperties.$luxon = DateTime
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
