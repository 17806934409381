import ajax from '@/utils/ajax'

// Initial state
const state = () => ({
  machines: []
})

// Getters
const getters = {
  machines: state => state.machines
}

// Actions
const actions = {
  async getMachines({ commit, rootState }) {
    const response = await ajax('machines', {
      token: rootState.auth.token
    })
    commit('setMachines', response.machines)
  }
}

// Mutations
const mutations = {
  setMachines(state, machines) {
    state.machines = machines
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
