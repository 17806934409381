<template>
  <div class="view">
      <div class = "machine-prop tile is-ancestor">
        <div class = "machine-icon tile is-child is-2" v-if="currentMachine" >
          <h1 class="title has-text-centered">{{ currentMachine.vehicle_make }} {{ currentMachine.vehicle_model }}</h1>
          <MachineIcon :machine="currentMachine"/>
        </div>
        <div class="machine-prop tile is-child" v-if="currentMachine">
          <p class="text"> <b>Machine Type:</b> {{currentMachine.nrmm_type}}</p>
          <p class="text"> <b>MVI Level:</b> {{currentMachine.mvi_level}}</p>
          <p class="text"> <b>Stage Tier:</b> {{currentMachine.engine_stage_tier}}</p>
          <p class="text"> <b>Brandstof:</b> {{currentMachine.fuel_type}}</p>
        </div>
        <div class="machine-prop tile is-child" v-if="currentMachine">
          <p class="text"> <b>Voertuiggewicht:</b> {{currentMachine.weight_kg}} kg</p>
          <p class="text"> <b>Nominaal Vermogen :</b> {{currentMachine.power_kw}} kW</p>
          <p class="text"> <b>Nominaal Motortoerental:</b> {{currentMachine.engine_rpm}} RPM</p>
        </div>
        <div class="machine-prop tile is-child" v-if="currentMachine">
          <p class="text"> <b>Motormerk:</b> {{currentMachine.engine_make}}</p>
          <p class="text"> <b>Motormodel:</b> {{currentMachine.engine_model}}</p>
          <p class="text"> <b>Motor Bouwjaar:</b> {{currentMachine.engine_build_year}}</p>
        </div>
        <div class="machine-prop tile is-child" v-if="currentMachine">
          <p class="text"> <b>DPF beschikbaar:</b> {{currentMachine.has_dpf ? 'Ja':'Nee'}}</p>
          <p class="text"> <b>SCR beschikbaar:</b> {{currentMachine.has_scr ? 'Ja':'Nee'}}</p>
          <p class="text"> <b>DOC beschikbaar:</b> {{currentMachine.has_doc ? 'Ja':'Nee'}}</p>
          <p class="text"> <b>EGR beschikbaar:</b> {{currentMachine.has_egr ? 'Ja':'Nee'}}</p>
        </div>
    </div>
    <div class="chart-box">
      <div class="tile is-horizontal is-pulled-right">
        <div class="sorter">
          <p class="label">Periode:</p>
          <div class="select">
            <select v-model="selectedPeriodOption">
              <option v-html="option.label" v-for="(option) of periodOptions" :value="option.value" :key="option.value" />
            </select>
          </div>
        </div>
        <div class="sorter">
          <p class="label">Data opties:</p>
          <div class="select">
            <select v-model="selectedDisplayOption">
              <option v-html="option.label" v-for="(option) of displayOptions" :value="option.value" :key="option.value" />
            </select>
          </div>
        </div>
      </div>
      <BarChart
        v-if="machineAggregates"
        :chartData="chartData"
      />
    </div>
    <div class="aggregate-table">
      <MachineAggregate v-if="machineAggregates" :machine_aggs ="machineAggregates"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BarChart from '@/components/BarChart'
import MachineAggregate from '@/components/MachineAggregate'
import MachineIcon from '@/components/MachineIcon'

export default {
  name: 'Machine',
  components: {
    BarChart,
    MachineAggregate,
    MachineIcon
  },
  data() {
    return {
      periodOptions:[{
        label: 'Laatste 7 actieve dagen',
        value: -7
      }, {
        label: 'Laatste 14 actieve dagen',
        value: -14
      }, {
        label: 'Alle actieve periode',
        value: 0
      }],
      displayOptions: [{
        label: 'Gemiddelde NOx per dag',
        value: 'avg_nox_g_h'
      }, {
        label: 'Aantal draaiuren',
        value: 'operating_hrs'
      }, {
        label: 'Gemiddelde brandstofverbruik per dag',
        value: 'avg_fuel_l_h'
      }],
      selectedPeriodOption: 0,
      selectedDisplayOption: 'avg_nox_g_h'
      }
  },
  watch: {
    selectedDisplayOption(val) {
      this.selectedDisplayOption = val
    },
    selectedPeriodOption(val) {
      this.selectedPeriodOption = val
    }
  },
  computed: {
    ...mapGetters(['currentMachine', 'machineAggregates']),
    chartData() {
      const dict_val_to_label_data = {
        'avg_nox_g_h': {
          'label': 'Gemiddelde NOx per dag [g/h]',
          'data': this.machineAggregates.slice(this.selectedPeriodOption).map(
            item => item.daily_nox_g_cumul / item.daily_operating_hrs)
        },
        'operating_hrs': {
          'label': 'Totaal draaiuren per dag [uren]',
          'data': this.machineAggregates.slice(this.selectedPeriodOption).map(
            item => item.daily_operating_hrs),
        },
        'avg_fuel_l_h': {
          'label': 'Gemiddelde brandstof verbruik [l/h]',
          'data': this.machineAggregates.slice(this.selectedPeriodOption).map(
            item => item.daily_fuel_l_cumul / item.daily_operating_hrs),
        }
      }
      return {
        labels: this.machineAggregates.slice(this.selectedPeriodOption).map(item => this.$luxon.fromISO(item.datetime).toFormat('DDD')),
        datasets: [
          {
            label: dict_val_to_label_data[this.selectedDisplayOption]['label'],
            backgroundColor: '#f87979',
            data: dict_val_to_label_data[this.selectedDisplayOption]['data']
          }]
      }
    }
  },
  beforeCreate() {
    // Fetch machine property and aggregates before creating this view
    this.$store.dispatch('getMachine', { machine_id: this.$route.params.id })
  },
  unmounted() {
    // Clear the current machine
    this.$store.dispatch('clearCurrentMachine')
  }
}
</script>

<style scoped>
.title{
  font-size: 22px;
}
.machine-prop {
  align-items: left;
}
.machine-prop .text {
  text-align: left;
  font-size: 16px;
}
.chart-box {
  padding-top: 20px;
}
.aggregate-table{
  padding-top: 20px;
}
.sorter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-end;
  margin-right: 20px
}
.sorter .label {
  margin-bottom: 0;
  margin-right: 20px;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: black;
}
</style>
