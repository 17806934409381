import { createRouter, createWebHashHistory } from 'vue-router'
import Machines from '@/views/Machines'
import Projects from '@/views/Projects'
import Machine from '@/views/Machine'
import store from '@/store'

import { getCookie, checkJWT } from '@/utils/cookies'
import getEnv from '@/utils/env'

const routes = [
  { path: '/:pathMatch(.*)*', redirect: '/' }, // catch-all
  { path: '/:pathMatch(.*)', redirect: '/'}, // catch-all
  {
    path: '/',
    name: 'Machines',
    component: Machines
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/machine/:id',
    name: 'Machine',
    component: Machine
  },
  {
    path: '/login',
    name: 'Inloggen',
    component: () => import('@/views/Login')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // Validate token
  const token = getCookie(getEnv('VUE_APP_AUTH_COOKIE_NAME'))
  const validToken = checkJWT(token)
  // Invalid token? Logout and redirect to /login
  if (!validToken) {
    if (token) store.dispatch('logout') // Clear token
    if (to.path === '/login') return next()
    return next({ path: '/login', replace: true })
  }
  // Load the requested page
  return next()
})

export default router
