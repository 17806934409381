import ajax from '@/utils/ajax'

// Initial state
const state = () => ({
  currentMachine: null,
  machineAggregates: []
})

// Getters
const getters = {
  currentMachine: state => state.currentMachine,
  machineAggregates: state => state.machineAggregates
}

// Actions
const actions = {
  async getMachine({ commit, rootState }, { machine_id }) {
    const response = await ajax(`machine_aggregates?machine_id=${machine_id}`, {
      token: rootState.auth.token
    })
    commit('setMachineAggregates', response)
  },
  clearCurrentMachine({ commit }) {
    commit('removeCurrentMachine')
  }
}

// Mutations
const mutations = {
  setMachineAggregates(state, { current_machine, machine_agg }) {
    state.currentMachine    = current_machine
    state.machineAggregates = machine_agg
  },
  removeCurrentMachine(state) {
    state.currentMachine    = null
    state.machineAggregates = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
