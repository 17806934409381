<template>
  <article class="machine-row tile">
    <div class="tile is-3 center">
      <p class="number">{{index}}</p>
      <div>
        <router-link :to="`/machine/${machine.id}`" class="title">{{machine.vehicle_make}} {{machine.vehicle_model}}</router-link>
        <p v-if="machine.start_date">Start meting: {{$luxon.fromISO(machine.start_date).toFormat('DDD')}}</p>
      </div>
      <div class = "tile is-1"></div>
      <div class="tags tile is-3 center">
        <div class="tag is-info is-normal"> {{machine.engine_stage_tier.slice(0,7)}}</div>
        <div class="tag is-primary is-normal">{{machine.power_kw}} kW</div>
      </div>
    </div>
    <div class="tile is-2 machine-owner is-vertical">
      <p class="label">{{machine.company_name}}</p>
      <div class="machine-type">
        <machineIcon :machine="machine"/>
        <p class="tag">{{machine.nrmm_type}}</p>
      </div>
    </div>
    <div class="tile is-7">
      <div class="tile is-2 kpi-row is-child">
        <div class="number">{{addCommas(Math.round(machine.total_operating_hrs))}}</div>
        <div class="label">Draaiuren</div>
      </div>
      <div class="tile is-2 kpi-row is-child">
        <div class="number">{{addCommas(Math.round(machine.total_nox_g))}}</div>
        <div class="label">Totaal NOx [g]</div>
      </div>
      <div class="tile is-2 kpi-row is-child">
        <div class="number">{{addCommas(Math.round(machine.total_fuel_l))}}</div>
        <div class="label">Totaal brandstofverbruik [l]</div>
      </div>
      <div class="tile is-2 kpi-row is-child">
        <div class="number" v-if="machine.avg_nox_g_h">{{addCommas(Math.round(machine.avg_nox_g_h))}}</div>
        <div class="number" v-else>&infin;</div>
        <div class="label">Gemiddelde NOx [g/h]</div>
      </div>
      <div class="tile is-2 kpi-row is-child">
        <div class="number">{{addCommas(Math.round(machine.avg_fuel_l_h))}}</div>
        <div class="label">Gemiddelde brandstofverbruik [l/h]</div>
      </div>
      <div class="tile is-2 kpi-row is-child">
        <div class="number">{{addCommas(Math.round(machine.avg_nox_g_l))}}</div>
        <div class="label">NOx per liter brandstof [g/l]</div>
      </div>
    </div>
  </article>
</template>
<script>
import MachineIcon from '@/components/MachineIcon'
import { addCommas } from '@/utils/parser'
export default {
  name: 'MachineRow',
  props: ['machine', 'index'],
  components: {
    MachineIcon
  },
  methods: {
    addCommas(n) {
      return addCommas(n)
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 22px;
}
div.label, span.label {
  margin: 0;
}
.tag {
  font-size: 15px;
  margin-right: 10px;
  margin-left: 10px;
}
.machine-row {
  display: flex;
  align-items: center;
}
.kpi-row {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  flex-direction: row;
  align-self: baseline;
}
.kpi-row .label {
  font-size: 12px;
}
.kpi-row .number {
  margin-right: 0;
}
.kpi-row span + span {
  margin-left: 10px;
}
.machine-row .title {
  margin-bottom: 0;
}
.center {
  align-items: center;
}
.kpi-box .label {
  font-size: 12px;
}
.kpi-box .number {
  margin-right: 0;
}
.machine-owner {
  flex-direction: column;
  display:flex;
}
.machine-type {
  display: grid;
}


</style>
