<template>
  <article>
    <div class="heading">
      <span class="pull-left number">{{index}}</span>
      <router-link class="title" :to="`/machine/${machine.id}`">
        {{machine.vehicle_make}} {{machine.vehicle_model}}
      </router-link>
    </div>
    <p class="subtitle">{{machine.company_name}}</p>
    <div>
      <machineIcon :machine="machine" />
      <p class="tag">{{machine.nrmm_type}}</p><br/>
      <p class="tag is-info is-normal">{{machine.engine_stage_tier.slice(0,7)}}</p> &nbsp;
      <p class="tag is-primary is-normal">{{machine.power_kw}} kW</p>
    </div>
    <div>
      <p v-if="machine.start_date">Start meting: {{$luxon.fromISO(machine.start_date).toFormat('DDD')}}</p>
    </div>
    <div class="kpi-bar tile is-parent">
      <div class="kpi tile is-child">
        <div class="number">{{addCommas(Math.round(machine.total_operating_hrs))}}</div>
        <div class="label">Draaiuren</div>
      </div>
      <div class="kpi tile is-child">
        <div class="number">{{addCommas(Math.round(machine.total_nox_g))}}</div>
        <div class="label">Totaal NOx [g]</div>
      </div>
      <div class="kpi tile is-child">
        <div class="number">{{addCommas(Math.round(machine.total_fuel_l))}}</div>
        <div class="label">Totaal brandstofverbruik [l]</div>
      </div>
      <div class="kpi tile is-child">
        <div class="number" v-if="machine.avg_nox_g_h">{{addCommas(Math.round(machine.avg_nox_g_h))}}</div>
        <div class="number" v-else>&infin;</div>
        <div class="label">Gemiddelde NOx [g/h]</div>
      </div>
      <div class="kpi tile is-child">
        <div class="number">{{addCommas(Math.round(machine.avg_fuel_l_h))}}</div>
        <div class="label">Gemiddelde brandstofverbruik [l/h]</div>
      </div>
      <div class="kpi tile is-child">
      <div class="number">{{addCommas(Math.round(machine.avg_nox_g_l))}}</div>
        <div class="label">NOx per liter brandstof [g/l]</div>
        </div>
    </div>
  </article>
</template>
<script>
import MachineIcon from '@/components/MachineIcon'
import { addCommas } from '@/utils/parser'
export default {
  name: 'MachineBox',
  props: ['machine', 'index'],
  components: {
    MachineIcon
  },
  methods: {
    addCommas(n) {
      return addCommas(n)
    }
  }
}
</script>
<style scoped>
.box {
  text-align: center;
  display: grid;
}
.tag {
  margin-top: 10px;
  margin-bottom: 10px;
}
.heading {
  display: flex;
}
.heading .number {
  position: absolute;
}
.heading .title {
  margin-left: 60px;
  margin-right: 40px;
}
.kpi-bar {
  align-self: end;
  display: flex;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid lightgrey;
}
.kpi + .kpi {
  padding-right: 10px;
}
.kpi .number {
  margin-right: 0;
}
.kpi .label {
  font-size: 12px;
}
</style>
