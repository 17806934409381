import ajax from '@/utils/ajax'

// Initial state
const state = () => ({
  projects: []
})

// Getters
const getters = {
  projects: state => state.projects
}

// Actions
const actions = {
  async getProjects({ commit, rootState }) {
    const response = await ajax('projects', {
      token: rootState.auth.token
    })
    commit('setProjects', response.projects)
  }
}

// Mutations
const mutations = {
  setProjects(state, projects) {
    state.projects = projects
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
