<template>
  <div>
    <h1 class="title">Machines</h1>
    <div class="sorter">
      <p class="label">Sorteren op:</p>
      <div class="select">
        <select v-model="selectedSortOption">
          <option v-html="option.label" v-for="(option) of sortOptions" :value="option.value" :key="option.value" />
        </select>
      </div>
    </div>

    <div class="tile is-ancestor">
      <div v-for="(machine, index) in top3" :key="machine.id" class="tile is-parent">
        <MachineBox
          :machine="machine"
          :index="index + 1"
          class="tile is-child box"
        />
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-vertical">
        <MachineRow
          :machine="machine"
          :index="index + 4"
          v-for="(machine, index) in remaining"
          :key="machine.id"
          class="tile is-child is-12 box"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MachineBox from '@/components/MachineBox'
import MachineRow from '@/components/MachineRow'

export default {
  name: 'Machines',
  components: {
    MachineBox,
    MachineRow
  },
  data() {
    return {
      sortOptions: [{
        label: 'Aantal draaiuren',
        value: 'total_operating_hrs'
      }, {
        label: 'Total NOx uitstoot in grammen',
        value: 'total_nox_g'
      }, {
        label: 'Total brandstofverbruik in liters',
        value: 'total_fuel_l'
      }, {
        label: 'Gemiddelde NOx uitstoot in g/h',
        value: 'avg_nox_g_h'
      }, {
        label: 'Gemiddelde brandstofverbruik in liters/uur',
        value: 'avg_fuel_l_h'
      }],
      selectedSortOption: 'total_operating_hrs',
      sortedMachines: []
    }
  },
  watch: {
    selectedSortOption(val) {
      this.sortedMachines = this.sortedMachines.sort((x,y) => x[val] < y[val] || -(x[val] > y[val]))
    }
  },
  computed: {
    ...mapGetters(['machines']),
    top3() {
      return this.sortedMachines.slice(0, 3)
    },
    remaining() {
      return this.sortedMachines.slice(3)
    }
  },
  beforeCreate() {
    // Fetch all machines before creating this view
    this.$store.dispatch('getMachines').then(() => {
      this.sortedMachines = [...this.machines].sort((x,y) => x[this.selectedSortOption] < y[this.selectedSortOption] || -(x[this.selectedSortOption] > y[this.selectedSortOption]))
    })
  }
}
</script>

<style scoped>
.sorter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-end;
}
.sorter .label {
  margin-bottom: 0;
  margin-right: 20px;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: black;
}
</style>
