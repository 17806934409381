<template>
<!--  <p> Table</p>-->
  <table class="table is-striped is-fullwidth">
    <thead>
      <tr>
        <th>Tijdstip (meest recent) </th>
        <th>Totaal draaiuren [uren]</th>
        <th>Gemiddelde NOx [g/h]</th>
        <th>Gemiddelde Brandstof [l/h]</th>
      </tr>
    </thead>
    <tbody>
    <tr v-for="machine_agg in machine_aggs.slice().reverse()" :key="machine_agg.id">
      <td >{{ $luxon.fromISO(machine_agg.datetime).toFormat('DDD') }}</td>
      <td class="column-text">{{ Math.round(machine_agg.daily_operating_hrs * 10) / 10 }}</td>
      <td class="column-text">{{ Math.round(machine_agg.daily_nox_g_cumul / machine_agg.daily_operating_hrs * 10) / 10
        }}
      </td>
      <td class="column-text">{{ Math.round(machine_agg.daily_fuel_l_cumul / machine_agg.daily_operating_hrs * 10) / 10
        }}
      </td>

    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'MachineAggregate',
  props: ['machine_aggs'],
}
</script>

<style scoped>
</style>
