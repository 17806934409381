<template>
  <footer>
    <div>
      <img class="logo" src="@/assets/logo_tno.svg" />
      <p>
        Made with <font-awesome-icon :icon="['fas', 'heart']" /> by STL
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.logo {
  filter: invert(75%) sepia(7%) saturate(267%) hue-rotate(266deg)
    brightness(114%) contrast(89%);
}
svg {
  color: red;
}
a {
  color: #2c3e50;
}
a:hover {
  color: $grey-light;
}
p {
  font-size: 10px;
  margin-top: 1rem;
}
footer {
  background-color: $grey;
  height: 248px;
}
div {
  margin: 1rem auto;
  margin-bottom: -0.3rem;
  width: $full-width;
  max-width: calc(100% - 2rem);
  text-align: center;
}
img {
  width: 130px;
  @media (max-width: $medium-width) {
    width: 110px;
  }
}
</style>
