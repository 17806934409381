<template>
  <div class="tile box">
    <span class="number">{{project.id}}</span>
    <span class="title">{{project.description}}</span>
    <span class="project-row">
      <span class="label kpi-value">Machines:</span>
      <span class="number kpi-value">{{addCommas(project.num_machines)}}</span>
      <span class="label kpi-value">Aantal draaiuren [h]:</span>
      <span class="number kpi-value">{{addCommas(Math.round(project.total_operating_hrs))}}</span>
      <span class="label kpi-value">Totaal NOx in project [kg]: </span>
      <span class="number kpi-value">{{addCommas(Math.round(project.total_nox_kg))}}</span>
    </span>
  </div>
</template>
<script>
import { addCommas } from '@/utils/parser'
export default {
  name: "ProjectRow",
  props: ['project'],
  methods:{
    addCommas(n) {
      return addCommas(n)
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 24px;
}
.project-row {
  margin-top:10px;
  display: flex;
  align-items: center;
}
.kpi-value {
  display: flex;
  margin-left: 10px;
  align-items: center;
}
</style>