<template>
  <div>
    <GoogleMap class="map" :api-key="api_key" :center="center" :zoom="zoom">
      <Marker v-for="project in projects" :key="project.id" :options="getMarkerOptions(project)"/>
    </GoogleMap>
  </div>
</template>
<script>
import { GoogleMap, Marker } from "vue3-google-map";
import getEnv from '@/utils/env';
export default {
  name: 'Map',
  props: ['projects'],
  components: {GoogleMap, Marker},
  data() {
    return {
      center: {lat: 52.109264, lng: 5.180785},
      zoom: 8,
      api_key: getEnv('VUE_APP_GOOGLE_MAP_API_KEY')
    }
  },
  methods: {
    getMarkerOptions(project) {
      const center = { lat: project.center.coordinates[1], lng: project.center.coordinates[0] }
      const markerOptions = { position: center, label: `${project.id}`}
      return markerOptions
    }
  }
}
</script>
<style scoped>
.map {
  height: 500px;
  width: 100%;
}
</style>
