import { createStore, createLogger } from 'vuex'
import auth from './modules/auth'
import machines from './modules/machines'
import projects from './modules/projects'
import machine from './modules/machine'

const debug = process.env.VUE_APP_NODE_ENV !== 'production'

export default createStore({
    modules: {
      auth,
      machine,
      machines,
      projects
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
