<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item really-bold" to="/">EMPK</router-link>
    </div>
    <router-link
      to="/machines"
      class="navbar-item"
      v-if="isAuthenticated"
    >
      Machines
    </router-link>
    <router-link
      to="/projects"
      class="navbar-item"
      v-if="isAuthenticated"
    >
      Projecten
    </router-link>
    <div class="navbar-end">
      <div v-if="isAuthenticated" class="navbar-item" v-on:click="logout">
        <span v-if="currentUser">{{ currentUser.name }} Uitloggen</span>
        <span v-else>Uitloggen</span>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser'])
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/login'))
    }
  }
}
</script>

<style scoped>
.navbar {
  border-bottom: 1px solid black;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 52px;
  &.show-nav {
    max-height: 100%;
    overflow-x: auto;
  }
  a {
    max-height: 52px;
  }
}
.navbar-brand {
  font-size: 32px;
  font-weight: 900;
}
.navbar-item {
  cursor: pointer;
}
</style>
