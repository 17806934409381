/*
  This is a simple Ajax library that formats our API calls. I prefer to use this re-usable
  lib cause it's simple and accounts for a customisable timeout, as well as wraps errors,
  sets headers, etc.
*/
import store from '@/store'
import router from '@/router'
import getEnv from '@/utils/env'

export default async (
  endpoint,
  {
    token,
    method = 'GET',
    body,
    file,
    timeout = 20000,
    noReply = false,
    headers
  } = {}
) => {
  const base_uri = getEnv('VUE_APP_API_URL')
  const uri = `${base_uri}/${endpoint}`
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    }
  }
  if (token) options.headers.Authorization = `Bearer ${token}`
  if (body) options.body = typeof body === 'string' ? body : JSON.stringify(body)
  if (file) {
    options.headers['Content-Type'] = file.type
    options.body = file
  }

  const onTimeout = setTimeout(() => {
    throw new Error(`network_timeout_on_${endpoint}`)
  }, timeout)
  let response

  try {
    const request = await fetch(uri, options)
    if (request.status === 401 && endpoint !== 'login') {
      // Clear token and redirect to login
      store.dispatch('logout').then(() =>
        router.push({
          name: 'Login',
          replace: true
        })
      )
    }
    if (noReply) return

    // Check for Content-Length
    const contentLength = request.headers.get('Content-Length')
    if (contentLength !== '0') {
      response = await request.json()
    }

  } finally {
    clearTimeout(onTimeout)
  }

  return response
}
