import jwt from 'jsonwebtoken'
import getEnv from '@/utils/env'

const setCookie = (name, content, expiration) => {
  document.cookie = `${name}=${content}; expires=${expiration}`
}

const getCookie = name => {
  const match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'))
  return match ? match[1] : null
}

const deleteCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

const setAuthCookie = token => {
  const tokenExpiration = jwt.decode(token).exp
  const authCookie = getEnv('VUE_APP_AUTH_COOKIE_NAME')
  document.cookie = `${authCookie}=${token}; expires=${tokenExpiration}`
}

const checkJWT = token => {
  if (token && jwt.decode(token)) {
    const expiry = jwt.decode(token).exp
    const now = new Date()
    return now.getTime() < expiry * 1000
  }
  deleteCookie(getEnv('VUE_APP_AUTH_COOKIE_NAME'))
  return false
}

export { setCookie, getCookie, deleteCookie, setAuthCookie, checkJWT }
