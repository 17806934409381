<template>
  <Navbar />
  <router-view class="view" />
  <Footer class="footer" />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.view {
  min-height: calc(100% - 300px);
  display: flex;
  flex-direction: column;
  margin: 2rem 4rem 4rem;
  width: $full-width;
  max-width: calc(100% - 2rem);
  text-align: left;
}
</style>
